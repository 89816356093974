<template>
  <Modal v-model="orderCancelPromptModal" :title="$t('key1000875')" width="750" :transfer="false" class-name="orderCancelPromptStyle"
    :mask-closable="false" @on-visible-change="orderCancelPromptModalChange">
    <Alert type="warning" show-icon>{{ $t('key1000876') }}</Alert>
    <div class="table_box mt20">
      <Table
        highlight-row
        border
        max-height="560"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData">
      </Table>
    </div>
    <template #footer>
      <template v-if="btnStatus">
        <Button class="mr15" type="primary" @click="orderCancelPromptModal = false">{{ $t('key1000138') }}</Button>
      </template>
      <template v-else>
        <Button class="mr15" @click="orderCancelPromptModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" v-if="continueSort" @click="continueSortingBtn">{{ $t('key1000877') }}</Button>
        <Button type="primary" v-else @click="reMoveUtboundOrderBtn">{{ $t('key1000878') }}</Button>
      </template>
    </template>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "orderCancelPromptModal",
  components: {},

  mixins: [Mixin],
  props: {
    // 操作按钮的状态
    btnStatus: {
      type: Boolean,
      default: false
    },
    // 是否显示继续分拣按钮
    continueSort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let v = this;
    return {
      orderCancelPromptModal: false,
      tableColumns: [
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000270'),
          width: 70,
          align: 'center',
          type: 'index'
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879'),
          key: 'packageCode',
          minWidth: 110,
          align: 'left',
          render(h, params) {
            let packageCode = '';
            if (v.system === 'yms') {
              packageCode = params.row.packageCode;
            } else {
              packageCode = params.row.pickingNo;
            }
            return h('span', packageCode)
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000205'),
          key: 'skuInfo',
          align: 'left',
          minWidth: 220,
          render(h, params) {
            let skuImgPath = params.row._productInfo.primaryImage;
            let spuImgPath = params.row._productInfo._spuInfo.primaryImage
            let imgPath = skuImgPath ? skuImgPath : spuImgPath;
            return h('div', {class: 'flex mt5 mb5'}, [
              v.tableImg(h, params, null, imgPath),
              h('div', {class: 'ml5'}, [
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', {
                    style: {
                      width: '70px',
                      textAlign: 'right'
                    }
                  }, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000085')),
                  h('p', {
                    style: {
                      flex: 1
                    }
                  }, params.row._productInfo._spuInfo.name),
                ]),
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', {
                    style: {
                      width: '70px',
                      textAlign: 'right'
                    }
                  }, 'SKU：'),
                  h('p', {
                    style: {
                      flex: 1
                    }
                  }, params.row._productInfo.ymsSku),
                ]),
              ])
            ]);
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000345'),
          key: 'quantity',
          align: 'left',
          width: 90
        },
      ],
      tableData: [],
      basicData: []
    }
  },
  methods: {
    // 处理列表数据
    handleTableData(data) {
      let v = this;
      let ymsProductGoodsIds = [];
      data.map((item) => {
        if (v.system === 'yms') {
          ymsProductGoodsIds.push(item.ymsProductGoodsId);
        } else {
          ymsProductGoodsIds.push(item.productGoodsId);
        }
      });
      if (ymsProductGoodsIds.length > 0) {
        if (v.system === 'yms') {
          (async () => {
            v.tableData = await v.getProductInfoByIds(ymsProductGoodsIds, data);
          })();
        } else {
          (async () => {
            v.tableData = await v.getProductInfoByIds(ymsProductGoodsIds, data,
              false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
          })();
        }

      }
    },
    // 移除已取消的出库单
    reMoveUtboundOrderBtn() {
      let v = this;
      let list = [];
      let url = '';
      if (v.tableData.length > 0) {
        v.tableData.map((item) => {
          if (v.system === 'yms') {
            list.push(item.ymsPickingOrderRelatedId);
          } else {
            list.push(item.pickingGoodsRelationId);
          }
        });
      }
      if (v.system === 'yms') {
        url = api.put_ymsPickingOrderRelated_deleteYmsPickingRelatedByIds;
      } else {
        url = api.put_wmsPickingGoods_deletePickingGoodsRelatedByIds;
      }
      v.axios.put(url, list, {loading: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(res => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          v.$emit('updateData', data);
          v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000084'));
          v.orderCancelPromptModal = false;
        }
      });
    },
    // 继续分拣（继续走物流分拣的正常流程）
    continueSortingBtn() {
      this.orderCancelPromptModal = false;
      this.$emit('continueSortingFun', true);
    },
    // 监听弹窗
    orderCancelPromptModalChange(value) {
      if (!value) {
        this.basicData = [];
        this.tableData = [];
      }
    }
  },
  watch: {
    basicData: {
      handler(data) {
        if (data.length > 0) {
          this.handleTableData(data);
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>